import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer, PageHeading } from "../components/shared-styles"

const ThankYou = () => {
  return (
    <Layout>
      <SEO title={`Contact`} />
      <ContentContainer>
        <PageHeading>Thank you!</PageHeading>
      </ContentContainer>
    </Layout>
  )
}

export default ThankYou
